exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/news-item.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-team-archive-tsx": () => import("./../../../src/templates/team-archive.tsx" /* webpackChunkName: "component---src-templates-team-archive-tsx" */),
  "component---src-templates-vacancies-archive-tsx": () => import("./../../../src/templates/vacancies-archive.tsx" /* webpackChunkName: "component---src-templates-vacancies-archive-tsx" */),
  "component---src-templates-vacancy-item-tsx": () => import("./../../../src/templates/vacancy-item.tsx" /* webpackChunkName: "component---src-templates-vacancy-item-tsx" */)
}

